import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/pengs/Projects/xijingge/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Button } from './Button';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
  <Button path='/xj' mdxType="Button">Click me</Button>
  <Button kind='secondary' mdxType="Button">Click me</Button>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      